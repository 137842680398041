import { A } from "@solidjs/router";
import { downloadHubbleQRCode, HubbleFullSvg } from "~/assets/assets";
import { config } from "~/data/config";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

export function Footer() {
  return (
    <div>
      <div class="hidden h-[550px]  flex-col items-start justify-start gap-10 bg-baseTertiaryLight py-[60px] lg:inline-flex">
        <div class="inline-flex items-center justify-start gap-8 self-stretch">
          <div class="flex h-10 items-center justify-start">
            <HubbleFullSvg class="h-9" />
          </div>
          <div class="h-px shrink grow basis-0 bg-baseTertiaryDark"></div>
        </div>
        <div class="inline-flex items-start justify-start gap-8 self-stretch">
          <div class="inline-flex h-[286px] shrink grow basis-0 flex-col items-start justify-between pr-20">
            <div class="flex shrink grow basis-0 flex-col items-start justify-start gap-8 self-stretch">
              <ContactUsSection />
              <DownloadAppSection />
            </div>
          </div>
          <div class="flex items-start justify-start gap-8">
            <div class="inline-flex w-[260px] flex-col items-start justify-start gap-8">
              <PopularCategories />
              <QuickLinkSection />
            </div>
            <div class="inline-flex w-[260px] flex-col items-start justify-start gap-8">
              <PopularGiftCardSection />
              <CompanyDetailSection />
            </div>
          </div>
        </div>
        <SocialMediaHandleAndEndNoteSection />
      </div>

      <div class="flex w-screen content-center justify-center pl-4 lg:hidden">
        <div class="flex w-fit flex-col content-center justify-center space-y-7">
          <div class="inline-flex items-center justify-start gap-8 self-stretch">
            <div class="flex h-10 items-center justify-start">
              <HubbleFullSvg class="h-5" />
            </div>
            <div class="h-px shrink grow basis-0 bg-baseTertiaryDark"></div>
          </div>
          <ContactUsSection />
          <DownloadAppSection />
          <PopularCategories />
          <QuickLinkSection />
          <PopularGiftCardSection />
          <CompanyDetailSection />
          <div class="flex flex-col content-start justify-start ">
            <SocialMediaAppsRow />
            <div class="text-medium leading-[18.20px] text-textNormal">
              © 2024 by Gullak Technologies Pvt Ltd
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6"></div>
    </div>
  );

  function ContactUsSection() {
    return (
      <div class="flex h-[87px] flex-col items-start justify-start gap-2 self-stretch">
        <div class="w-[266px] text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Contact us
        </div>
        <div class="flex h-[62px] flex-col items-start justify-start gap-1.5 self-stretch">
          <div class="cursor-pointer">
            <a
              href="mailto:support@myhubble.money"
              class="flex flex-row gap-1.5"
            >
              <div class="flex h-5 w-5 items-center justify-center">
                <PhosphorIcon
                  name="envelope-simple"
                  fontSize={16}
                  size="bold"
                />
              </div>
              <div class="cursor-pointer text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
                Support@myhubble.money
              </div>
            </a>
          </div>
          <div class="inline-flex items-start justify-start gap-1.5 self-stretch">
            <div class="flex h-5 w-5 items-center justify-center">
              <PhosphorIcon
                name="map-pin-simple-area"
                fontSize={16}
                size="bold"
              />
            </div>
            <div class="w-fit text-mediumSemiBold leading-[18.20px] text-textDark">
              Urban Vault, #591, 15th main road, 22nd Cross Rd, 3rd Sector, HSR
              Layout, Bengaluru, Karnataka 560102
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DownloadAppSection() {
    return (
      <div class="flex flex-col items-start justify-start gap-2">
        <div class="w-[266px] text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Download App
        </div>
        <div class="inline-flex items-center justify-start gap-3 self-stretch">
          <div class="hidden flex-col items-center justify-center rounded-xl border border-neutral-200 bg-baseTertiaryLight p-[8.14px] sm:inline-flex">
            <HubbleImage
              class="h-[103.73px] w-[103.73px]"
              src={downloadHubbleQRCode}
              alt="QR code to scan to download Hubble"
            />
          </div>
          <div class="flex max-w-[200px] flex-col content-start justify-start">
            <a
              href="https://play.google.com/store/apps/details?id=money.myhubble&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              class="h-[45px] max-w-[200px]"
            >
              <HubbleImage
                class="h-[45px] max-w-[200px]"
                alt="Get it on Google Play"
                src="https://assets-global.website-files.com/637f32081b688877381bdd42/65f7e2f1247b425fc60562e7_Frame%201000001830.png"
              />
            </a>
            <Spacer height={10}></Spacer>
            <a
              class="h-[45px] max-w-[200px]"
              href="https://apps.apple.com/us/app/hubble-shopping-discounts-app/id1614427933?itsct=apps_box_badge&amp;itscg=30200"
              style="display: inline-block; overflow: hidden; border-radius: 10px; width: 138px; height: 45px;"
            >
              <HubbleImage
                class=" h-[45px] w-[138px] max-w-[200px] rounded-ss-lg"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=138x45&amp;releaseDate=1649635200"
                alt="Download on the App Store"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }

  function PopularCategories() {
    return (
      <div class="flex h-[139px] flex-col items-start justify-start gap-2 self-stretch">
        <div class="text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Popular categories
        </div>
        <div class="flex h-[114px] flex-col items-start justify-start gap-1.5 self-stretch">
          <A
            class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline"
            href={config.paths.categoryPageBasePath + "food-gift-cards"}
          >
            Food
          </A>
          <A
            class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline"
            href={config.paths.categoryPageBasePath + "grocery-gift-cards"}
          >
            Groceries
          </A>
          <A
            class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline"
            href={config.paths.categoryPageBasePath + "hotels-gift-cards"}
          >
            Hotels
          </A>
          <A
            class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline"
            href={config.paths.categoryPageBasePath + "electronics-gift-cards"}
          >
            Electronics
          </A>
          <A
            class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline"
            href={config.paths.categoryPageBasePath + "fashion-gift-cards"}
          >
            Fashion
          </A>
        </div>
      </div>
    );
  }

  function QuickLinkSection() {
    return (
      <div class="flex h-[91px] flex-col items-start justify-start gap-2 self-stretch">
        <div class="text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Quick links
        </div>
        <div class="flex h-[66px] flex-col items-start justify-start gap-1.5 self-stretch">
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/check/gift-card-balance" target="_blank">
              Check Gift Card Balance
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/privacy-policy" target="_blank">
              Privacy policy
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/terms-and-conditions" target="_blank">
              Terms & conditions
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/refund-policy" target="_blank">
              Refund policies
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="https://store.hubble-rewards.com/" target="_blank">
              Redeem Hubble code
            </A>
          </div>
        </div>
      </div>
    );
  }

  function CompanyDetailSection() {
    return (
      <div class="flex h-[115px] flex-col items-start justify-start gap-2 self-stretch">
        <div class="self-stretch text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Company
        </div>
        <div class="flex h-[90px] flex-col items-start justify-start gap-1.5 self-stretch">
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/about-us" target="_blank">
              About
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/blog" target="_blank">
              Blog
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/careers" target="_blank">
              Careers
            </A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/faqs" target="_blank">
              FAQs
            </A>
          </div>
        </div>
      </div>
    );
  }

  function PopularGiftCardSection() {
    return (
      <div class="flex h-[139px] flex-col items-start justify-start gap-2 self-stretch">
        <div class="self-stretch text-f12w700 uppercase leading-none tracking-tight text-textNormal">
          Popular gift cards
        </div>
        <div class="flex h-[114px] flex-col items-start justify-start gap-1.5 self-stretch">
          <div class="cursor-pointer text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/buy/amazon-gift-card">Amazon gift card at 1.5% off</A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/buy/flipkart-gift-card">Flipkart gift card at 2% off</A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/buy/ikea-gift-card">Ikea gift card at 5% off</A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/buy/swiggy-gift-card">Swiggy gift card at 3% off</A>
          </div>
          <div class="cursor-pointer self-stretch text-mediumSemiBold leading-[18.20px] text-textDark hover:underline">
            <A href="/buy/zomato-gift-card">Zomato gift card at 2.5% off</A>
          </div>
        </div>
      </div>
    );
  }

  function SocialMediaHandleAndEndNoteSection() {
    return (
      <div class="inline-flex cursor-pointer items-center justify-start gap-8 self-stretch">
        <SocialMediaAppsRow />
        <div class="h-px shrink grow basis-0 bg-baseTertiaryDark"></div>
        <div class="text-mediumSemiBold leading-[18.20px] text-textNormal">
          © 2024 by Gullak Technologies Pvt Ltd
        </div>
      </div>
    );
  }

  function SocialMediaAppsRow() {
    return (
      <div class="flex gap-[9px] ">
        <div class="bg-primaryDark flex h-6 w-6 cursor-pointer content-center justify-center rounded-xl">
          <A
            href="https://www.instagram.com/hubblemoney/"
            target="_blank"
            aria-label="Instagram"
          >
            <PhosphorIcon
              name="instagram-logo"
              class="content-center justify-center text-textDark"
              fontSize={24}
              size="fill"
            />
          </A>
        </div>
        <div class=" bg-primaryDark flex h-6 w-6 cursor-pointer content-center justify-center rounded-xl">
          <A
            href="https://www.facebook.com/myhubble.money"
            target="_blank"
            aria-label="Facebook"
          >
            <PhosphorIcon
              name="facebook-logo"
              class=" content-center justify-center text-textDark"
              fontSize={24}
              size="fill"
            />
          </A>
        </div>
        <div class=" bg-primaryDark flex h-6 w-6 cursor-pointer content-center justify-center rounded-xl">
          <A
            href="https://twitter.com/hubblemoney"
            target="_blank"
            aria-label="X"
          >
            <PhosphorIcon
              name="x-logo"
              fontSize={24}
              size="fill"
              class=" content-center justify-center text-textDark"
            />
          </A>
        </div>
      </div>
    );
  }
}
